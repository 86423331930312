import axios from 'axios'
import { ERROR_TYPES } from '../constants/ErrorTypes'
import { SIGN_UP, SIGN_UP_ERROR } from '../types/actionTypes'
import { getServerErrors } from '../utils/serverErrors'
import { URLS } from '../utils/urls'

import showToast from './Toast'
import { getCSRFToken } from '../utils/cookies'

const signUpEmail =
  ({ userName, email, password }) =>
    async (dispatch) => {
      try {
        dispatch({
          type: SIGN_UP,
          payload: {
            loading: true
          }
        })

        const csrf = getCSRFToken()

        const params = {
          username: userName,
          email,
          password1: password,
          password2: password,
          csrfmiddlewaretoken: csrf
        }

        const response = await axios.post(`${URLS.base}accounts/signup/`, params)

        if (response) {
          dispatch(
            showToast({
              show: true,
              message: `Se ha enviado un correo a ${email}, por favor verifica tu cuenta.`,
              type: ERROR_TYPES.SUCCESS
            })
          )
          dispatch({
            type: SIGN_UP,
            payload: {
              loading: false
            }
          })
          return true
        } else {
          dispatch(
            showToast({
              show: true,
              message: 'Ups! Ocurrió un error.',
              type: ERROR_TYPES.ERROR
            })
          )
          dispatch({
            type: SIGN_UP_ERROR,
            payload: {
              loading: false
            }
          })
          return false
        }
      } catch (error) {
        dispatch(
          showToast({
            show: true,
            message: getServerErrors(error),
            type: ERROR_TYPES.ERROR
          })
        )
        dispatch({
          type: SIGN_UP_ERROR,
          payload: {
            loading: false
          }
        })
      }
    }

export default signUpEmail
