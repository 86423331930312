import axios from 'axios'
import { URLS } from './urls'
import { getCSRFToken, getSessionID } from './cookies'
import { deleteUserSessionStorage, getUserSessionStorage, setUserSessionStorage } from './userSessionStorage'

axios.defaults.withCredentials = true

export const signIn = async (username, password) => {
  if (URLS.use_jwt) {
    // JWT
    const params = {
      username,
      password
    }
    const response = await axios.post(`${URLS.base}token/`, params, { withCredentials: true })

    if (response) {
      // save user + token
      const accessToken = response.data.access
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`

      setUserSessionStorage({ username, accessToken })
      // window.location.href = '';
    }

    return response
  } else {
    // SESSION
    const csrf = getCSRFToken()
    const params = new FormData()
    params.append('username', username)
    params.append('password', password)
    params.append('csrfmiddlewaretoken', csrf)
    try {
      await axios({
        method: 'post',
        url: `${URLS.base}auth/login/`,
        data: params,
        headers: { 'Content-Type': 'multipart/form-data' }
        // withCredentials: true
      })
    } catch (e) {
      console.log(e)
    }
    // fetch user info
    try {
      const user = await axios.get(`${URLS.base}me`, {}, { useCredentials: true })
      setUserSessionStorage({ username: user.data.username, avatar: user.data.avatar })
      window.location.href = ''
    } catch (error) {
      deleteUserSessionStorage()
      return { error: true, response: 'Usuario y/o contraseña incorrectos' }
    }
    return { error: false }
  }
}

export const fetchCSRF = async () => {
  if (URLS.use_jwt) {
    // JWT - need to fetch the actual csrf token from backend
    const data = await axios.get(`${URLS.base}csrf`)
    // configure axios with access token if present
    axios.defaults.headers.common['X-CSRFTOKEN'] = data.data.csrfToken
    const session = getUserSessionStorage()
    if (session && session.accessToken !== '') {
      axios.defaults.headers.common.Authorization = `Bearer ${session.accessToken}`

      // fetch user info
      try {
        const user = await axios.get(`${URLS.base}me`, {}, { useCredentials: true })
        setUserSessionStorage({ username: user.data.username, avatar: user.data.avatar, accessToken: session.accessToken, credits: user.data.credits.credits, costs: user.data.costs })
      } catch (error) {
        deleteUserSessionStorage()
        // logout();
        // window.location.href = '/';
      }
    }
  } else {
    // SESSION - csrf token is sent within cookies from server
    // do a request
    await axios.get(`${URLS.base}csrf`)
    // grab it from cookies
    const cookie = getCSRFToken()
    if (cookie) {
      axios.defaults.headers.common['X-CSRFTOKEN'] = cookie

      // fetch user info
      try {
        const user = await axios.get(`${URLS.base}me`, {}, { useCredentials: true })
        setUserSessionStorage({ username: user.data.username, avatar: user.data.avatar, credits: user.data.credits.credits, costs: user.data.costs })
      } catch (error) {
        deleteUserSessionStorage()
        // logout();
        // window.location.href = '/';
      }
    }
  }
}

export const logout = async () => {
  deleteUserSessionStorage()
  await axios.get(`${URLS.base}auth/logout/`)
  window.location.href = '/'
}

export const isLoggedIn = () => {
  if (URLS.use_jwt) {
    // JWT
    const session = getUserSessionStorage()
    if (session) return session.loggedin
    return false
  } else {
    // SESSION
    const sessionId = getSessionID()
    return sessionId != null
  }
}
export const proxy = async (url) => {
  return await axios.post(`${URLS.base}preview/`, { url })
}
